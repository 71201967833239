import request from '@/utils/request'


// 查询短剧标签列表
export function listTag(query) {
  return request({
    url: '/video/video-tags/list',
    method: 'get',
    params: query
  })
}

// 查询短剧标签分页
export function pageTag(query) {
  return request({
    url: '/video/video-tags/page',
    method: 'get',
    params: query
  })
}
export function changeIsOpen(data) {
  return request({
    url: '/video/video-tags/changeIsOpen',
    method: 'post',
    data: data
  })
}
// 查询短剧标签详细
export function getTag(data) {
  return request({
    url: '/video/video-tags/detail',
    method: 'get',
    params: data
  })
}

// 新增短剧标签
export function addTag(data) {
  return request({
    url: '/video/video-tags/add',
    method: 'post',
    data: data
  })
}

// 修改短剧标签
export function updateTag(data) {
  return request({
    url: '/video/video-tags/edit',
    method: 'post',
    data: data
  })
}

// 删除短剧标签
export function delTag(data) {
  return request({
    url: '/video/video-tags/delete',
    method: 'post',
    data: data
  })
}
